@mixin border-radius($radius: .5rem) {
    @if $enable-rounded {
        border-radius: $radius;
    }
}

@mixin border-top-radius($radius) {
    @if $enable-rounded {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    }
}

@mixin border-right-radius($radius) {
    @if $enable-rounded {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
    }
}

@mixin border-bottom-radius($radius) {
    @if $enable-rounded {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin border-left-radius($radius) {
    @if $enable-rounded {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}


//  Usage
//	.div{
//		@include @border-radius(10px);
//	}

//  Return
//	.div{
//		-webkit-border-radius: 10px;
//		-moz-border-radius: 10px;
//		-o-border-radius: 10px;
//		border-radius: 10px;
//	}