@import 'mixins/functions';
@import 'mixins/colors';

@import 'variables/all';

@import 'mixins/transition';
@import 'mixins/border-radius';
@import 'mixins/letter-spacing';

#file-nav {
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 1000000;
    color: #ffffff;
    @include transition;

    .page-list {
        padding: 20px 10px 0;
        position: absolute;
        left: 0;
        bottom: 0;
        display: none;
        background: #323a47;
        width: 250px;
        height: 400px;

        #scroll-nav {
            height: 280px;
            overflow: auto;
        }

        h3 {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 3px;
            margin-bottom: 10px;
            text-align: center;
        }

        a {
            display: block;
            padding: 3px 10px;
            color: $white;
            @include letter-spacing(20);
            font-size: 12px;
            line-height: $line-height-base;
            opacity: 0.4;

            &:hover {
                opacity: 1;
                text-decoration: none;
            }
        }

        .mCSB_scrollTools {
            .mCSB_draggerRail {
                background: #ffffff;
                opacity: 0.1;
                width: 4px;
            }

            .mCSB_dragger {
                .mCSB_dragger_bar {
                    background: #8593a9;    
                    width: 6px;
                }
            }
        }
    }

    #file-nav-toggle {
        width: 60px;
        height: 60px;
        display: block;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 100%;
        @include border-radius(50%);
        background: #2a313d;
        font-size: 12px;
        letter-spacing: 3px;
        padding: 10px 0 0 4px;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @include transition;
        color: #ffffff;

        span {
            font-size: 7px;
            letter-spacing: 1px;
            display: block;
            position: relative;
            left: -2px;
            font-weight: 400;
        }

        small {
            display: block;
            width: 17px;
            margin: 0 auto 7px;
            @include transition;

            span {
                display: block;
                height: 2px;
                background: #ffffff;
                margin-bottom: 2px;
            }
        }

        &:hover { opacity: 1; }

        &.active {
            @include border-radius;

            small {
                width: 3px;
            }
        }
    }

    .status {
        color: #ffffff;
        font-size: 12px;
        position: absolute;
        right: 10px;
        bottom: 7px;
        width: 170px;
        text-align: center;
        text-transform: uppercase;

        .single {
            width: 50%;
            float: left;
            font-size: 32px;
            line-height: 100%;
            font-weight: 800;
            letter-spacing: 3px;

            span {
                display: block;
                font-size: 10px;
                letter-spacing: 2px;
                font-weight: 300;
                line-height: 18px;
            }
        }
    }
}