@mixin transition($transition...) {
    @if $enable-transitions {
        @if length($transition) == 0 {
            transition: $transition-base;
        } @else {
            transition: $transition;
        }
    }

    @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

@mixin animate($time: 200ms, $timing: ease-out) {
    transition: all $time;
    transition-timing-function: $timing;
}

//  Usage
//  .div{
//      @include animate(350ms, ease-in-out);
//  }

//  Return
//  .div{
//      -webkit-transition: all 350ms;
//      -moz-transition: all 350ms;
//      -o-transition: all 350ms;
//      -ms-transition: all 350ms;
//      transition: all 350ms;
//      
//      -webkit-transition-timing-function: ease-in-out;
//      -moz-transition-timing-function: ease-in-out;
//      -o-transition-timing-function: ease-in-out;
//      -ms-transition-timing-function: ease-in-out;a
//      transition-timing-function: ease-in-out;
//  }