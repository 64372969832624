$enable-rounded:            true;
$enable-shadows:            true;
$enable-gradient:            true;
$enable-transitions:        true;
$enable-grid-classes:       true;

@import 'breakpoints';
@import 'colors';
@import 'typography';
@import 'icons';

@import 'grid';

@import 'buttons';
@import 'forms';
@import 'psd';

// > Shadow
$box-shadow-sm: 0 .25rem .5rem rgba($black, .15);

// > Transition
$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

// > Z-index
$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
$zindex-modal-backdrop:             1040;
$zindex-modal:                      1050;
$zindex-popover:                    1060;
$zindex-tooltip:                    1070;

// > Spacer
$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3)
);
