//================================================================================================================
//  >   00 Chapters
//================================================================================================================
//  *   CH01 - Global Color Variable
//  *   CH02 - Text Color
//  *   CH03 - Background Color
//  *   CH04 - Border Color
//  *   CH05 - Box Shadow Color
//  *   CH06 - Text Color
//  *   CH07 - Button Color


//================================================================================================================
//  >   CH01 - Global Color Variable
//================================================================================================================

//  >>  Shades of gray
$white:    #ffffff;
$gray-100: #f8f8f8;
$gray-200: #9b9b9b;
$gray-300: #555555;
$gray-400: #4a4a4a;
$gray-500: #333333;
$black:    #000000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500
    ),
        $grays
);

//  >>  Global color
$brand:   #ee1c26;
$red:     #dc3545;
$yellow:  #ffc107;
$green:   #28a745;
$cyan:    #17a2b8;

//  >>  Theme color
$primary:       $brand;
$secondary:     $gray-200;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-400;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary':    $primary,
        'secondary':  $secondary,
        'success':    $success,
        'info':       $info,
        'warning':    $warning,
        'danger':     $danger,
        'light':      $light,
        'dark':       $dark
    ),
        $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

//  >>  Color contrast threshold
// The YIQ lightness value that determines when the lightness of color changes from 'dark' to 'light'. Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $dark;
$yiq-text-light:            $white;


//  >>  Variable Link Text Color
$link-colors:   (
    primary: (
        default: #007bff,
        hover: #0056b3,
        active: #0056b3,
        disabled: #6c757d,
    ),
    secondary:  (
        default: #6c757d,
        hover: #6c757d,
        active: #6c757d,
        disabled: #6c757d
    ),
    // etc..
);

//  >>  Variable Background Color
$background-colors: (
    primary: (
        default: #8e3329
    ),
    secondary: (
        default: #ff0000,
    ),
    // etc..
);

//  >>   Variable Border Color
$border-colors: (
    primary: (
        default: #8e3329
    ),
    secondary: (
        default: #ff0000
    ),
    // etc..
);

//  >>   Variable Box shadow Color
$box-shadow-colors: (
    primary: (
        default: #8e3329
    ),
    secondary: (
        default: #ff0000
    ),
    // etc..
);

//  >>   Variable Text Color
$text-colors:    (
    // Paragraph
    p-primary: (
        default: #000000
    ),
    p-secondary: (
        default: #ffffff
    ),
    // Heading
    h-primary: (
        default: #333333
    ),
    h-secondary: (
        default: #999999
    ),
    // Label
    l-primary: (
        default: #000000
    ),
    l-secondary: (
        default: #ffffff
    ),
    // etc..
);

//  >>   Variable Button Color
$button-colors: (
    // Button Text Color
    color-primary: (
        default: #ffffff,
        hover: #ffffff,
        active: #ffffff,
        disabled: #ffffff,
    ),
    // Button Background Color
    background-color-primary: (
        default: #007bff,
        hover: #0069d9,
        active: #0062cc,
        disabled: null
    ),
    // etc...
);



//================================================================================================================
//  >   CH02 - Link Text Color
//================================================================================================================
$link-color-key: 'base' !default;

@function link-color($name: primary, $variant: $link-color-key, $opacity: 1) {
    $color: null;

    // Get the color spectrum
    $color-spectrum: map-get($link-colors, $name);

    // Get the color variant
    @if $color-spectrum {
        $color: map-get($color-spectrum, $variant);
    }

    @return $color;
}

// Example usage
// .my-link-button {
//     color: link-color(secondary, default);

//     &:hover {
//         color: link-color(primary, hover);
//     }

//     &.secondary {
//         color: link-color(secondary);

//         &:hover {
//             color: link-color(secondary, hover);
//         }
//     }
// }



//================================================================================================================
//  >   CH03 - Background Color
//================================================================================================================
$background-color-key: 'base' !default;

@function background-color($name: primary, $variant: $background-color-key) {
    $color: null;

    // Get the color spectrum
    $color-spectrum: map-get($background-colors, $name);

    // Get the color variant
    @if $color-spectrum {
        $color: map-get($color-spectrum, $variant);
    }

    @return $color;
}

// Example usage
// .my-background-button {
//     background-color: background-color(secondary, default);

//     &:hover {
//         background-color: background-color(primary, hover);
//     }

//     &.secondary {
//         background-color: background-color(secondary);

//         &:hover {
//             background-color: background-color(secondary, hover);
//         }
//     }
// }



//================================================================================================================
//  >   CH04 - Border Color
//================================================================================================================
$border-color-key: 'base' !default;

@function border-color($name: primary, $variant: $border-color-key) {
    $color: null;

    // Get the color spectrum
    $color-spectrum: map-get($border-colors, $name);

    // Get the color variant
    @if $color-spectrum {
        $color: map-get($color-spectrum, $variant);
    }

    @return $color;
}

// Example usage
// .my-border-button {
//     border: 1px solid border-color(secondary, default);

//     &:hover {
//         border-color: border-color(primary, hover);
//     }
// }



//================================================================================================================
//  >   CH05 - Box Shadow Color
//================================================================================================================
$box-shadow-color-key: 'base' !default;

@function box-shadow-color($name: primary, $variant: $box-shadow-color-key) {
    $color: null;

    // Get the color spectrum
    $color-spectrum: map-get($box-shadow-colors, $name);

    // Get the color variant
    @if $color-spectrum {
        $color: map-get($color-spectrum, $variant);
    }

    @return $color;
}

// Example usage
// .my-box-shadow-button {
//     box-shadow: 0 0 10px box-shadow-color(secondary, default);
// }



//================================================================================================================
//  >   CH06 - Text Color
//================================================================================================================
$text-color-key: 'base' !default;

@function text-color($name: primary, $variant: $text-color-key) {
    $color: null;

    // Get the color spectrum
    $color-spectrum: map-get($text-colors, $name);

    // Get the color variant
    @if $color-spectrum {
        $color: map-get($color-spectrum, $variant);
    }

    @return $color;
}

// Example usage
// .my-text-color {
//     color: text-color(p-primary, default);
// }



//================================================================================================================
//  >   CH07 - Button Color
//================================================================================================================
$button-color-key: 'base' !default;

@function button-color($name: primary, $variant: $button-color-key) {
    $color: null;

    // Get the color spectrum
    $color-spectrum: map-get($button-colors, $name);

    // Get the color variant
    @if $color-spectrum {
        $color: map-get($color-spectrum, $variant);
    }

    @return $color;
}

// Example usage
// .my-button-primary {
//     background-color: button-color(background-color-primary, default);
//     color: button-color(color-primary, default);
// }

//================================================================================================================
//  >   CH08 - Social brand color
//================================================================================================================

// Sass Variables
// Social Media Brand Colors
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagrammagenta: #f00075;
$instagramblue: #4c5fd7;
$instagrampurple: #7232bd;
$instagramorange: #f46f30;
$instagramyellow: #ffdc7d;
$googleblue: #4285f4;
$googlered: #ea4335;
$googleyellow: #fbbc05;
$googlegreen: #34a853;
$pinterest: #bd081c;
$googleplus: #db4437;
$linkedin: #007bb5;
$vimeoblue: #1ab7ea;
$tumblr: #2c4762;
$snapchat: #fffc00;
$whatsappgreen: #25d366;
$whatsappteal1: #075e54;
$whatsappteal2: #128c7e;
$apple: #a6b1b7;
$amazon: #ff9900;
$microsoftred: #f35022;
$microsoftgreen: #80bb03;
$microsoftblue: #03a5f0;
$microsoftyellow: #ffb903;
$foursquarepink: #f94877;
$foursquarenavy: #073282;
$foursquareblue: #2d5be3;
$swarm: #ffa633;
$medium: #02b875;
$skypeblue: #00aff0;
$skypedkblue: #0078d7;
$android: #a4c639;
$stumbleupon: #e94826;
$flickrpink: #f40083;
$flickrblue: #006add;
$yahoo: #430297;
$soundcloud: #ff5500;
$spotifygreen: #1ed760;
$spotifydarkgreen: #1db954;
$dribbble: #ea4c89;
$slackpurple: #4d394b;
$slackskyblue: #70cadb;
$slackmosss: #3eb890;
$slackpink: #e01765;
$slackyellow: #e8a723;
$slackgreenblue: #1a937d;
$slackviolet: #472a49;
$slackolive: #65863a;
$slackred: #cc2027;
$reddit: #ff5700;
$deviantart: #05cc47;
$pocket: #ee4056;
$quora: #aa2200;
$quorablue: #2b6dad;
$slideshareorange: #e68523;
$slideshareblue: #00a0dc;
$fivehundredpx: #0099e5;
$vk: #4a76a8;
$listlyorange: #df6d46;
$listlyblue: #52b1b3;
$vine: #00b489;
$steam: #171a21;
$discord: #7289da;
$blue: #3251AD;
