//================================================================================================================
//  >   00 Chapters
//================================================================================================================
//  *   CH01 - Markdown title



//================================================================================================================
//  >   CH01 - TOC title
//================================================================================================================
// Set letterspacing from PSD value
// @usage
//      @include letter-spacing(100)

@mixin letter-spacing($ls) {
    letter-spacing: $ls / 1000 * 1em;
}
