// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $dark;

// Links
//
// Style anchor elements.

$link-color:                theme-color('primary');
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

// Fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-base: 'Helvetica Neue', Arial, sans-serif;

$font-weight: (
    light: 300,
    reg: 400,
    semi: 600,
);

$font-size-ref: (
    m: 12px,
    d: 16px,
);

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);

$line-height-base:            1.375;
$line-height-lg:              1.5;
$line-height-sm:              1.1;

$font-sizes: 
12,
13,
14,
16,
18,
20,
26,
32;
